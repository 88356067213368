.Service__buttons-separator {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: var(--text-color-secondary);
    display: inline-block;
    vertical-align: middle;
    margin: 5px 8px;
}

.Service__aside {
    padding: 20px;
}

.Service__warning {
    color: var(--yellow);
    margin-bottom: 20px;
}