.Select {
    display: inline-block;
    position: relative;
    width: 100%;
    cursor: pointer;
    user-select: none;
}

.Select__element {
    width: 100%;
    min-height: 34px;

    padding-right: 80px;
}

.Select__chevron {
    color: var(--icon-color);
    position: absolute;
    top: 4px;
    right: 10px;
    transition: 0.15s linear;
}

.Select__element:focus ~ .Select__chevron {
    transition: 0.15s linear;
    transform: rotateZ(180deg);
}

.Select__options {
    position: absolute;
    top: calc( 100% + 5px );
    left: 0;
    z-index: 500;

    width: 100%;
    padding: 0;
    box-sizing: border-box;

    border-radius: 5px;
    border: 0px solid var(--separator);

    background: var(--background);
    box-shadow: var(--shadow-1), var(--shadow-2);

    display: flex;
    flex-direction: column;
    row-gap: 5px;

    display: none;
    overflow: auto;

    max-height: 250px;
}

.Select__element:focus ~ .Select__options,
.Select__options:active {
    display: block;
}

.Select__options--above {
    top: auto;
    bottom: calc( 100% + 5px );
}

.Select__placeholder {
    color: var(--text-color-secondary)
}