.ModalRoot {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    width: 100%;
    height: 100%;

    background: var(--modal-cover);
    
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .ModalRoot {
        align-items: end;
    }
} 