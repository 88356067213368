.Spinner {
    display: inline-block;
    
    border-radius: 50%;
    border: 2px solid transparent;
    border-top: 2px solid var(--text-color-primary);
    border-right: 2px solid var(--text-color-primary);

    animation: spinner 1s linear infinite;
}


.Spinner--white {
    border-top: 2px solid var(--text-color-invert);
    border-right: 2px solid var(--text-color-invert);
}

.Spinner--currentColor {
    border-top: 2px solid currentColor;
    border-right: 2px solid currentColor;
}

.Spinner--8 {
    width: 8px;
    height: 8px;
}

.Spinner--12 {
    width: 12px;
    height: 12px;
}

.Spinner--24 {
    width: 24px;
    height: 24px;
}

.Spinner--30 {
    width: 30px;
    height: 30px;
}

.Spinner--48 {
    width: 48px;
    height: 48px;
}


@keyframes spinner {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(360deg);
    }
}