.Header {
    display: block;
    font-family: var(--font-family-headers);
}

.Header--level-1 {
    font-size: 40px;
    margin-bottom: 20px;
}

.Header--level-2 {
    font-size: 30px;
    margin-bottom: 10px;
}

.Header--level-3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.Header--level-4 {
    font-size: 20px;
}

.Header--level-5 {
    font-size: 16px;
}