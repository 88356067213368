.Radio {
    display: inline-block;
    margin: 7px 0;
}

.Radio__label {
    display: flex;
    column-gap: 5px;
    align-items: center;
}

.Radio__checkbox {
    display: none;
}

.Radio__Radio {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: var(--input-bg);
    position: relative;
    transition: 0.15s linear;
    cursor: pointer;
}

.Radio__Radio:hover {
    background: var(--input-hover);
    transition: 0.15s linear;
}

.Radio__checkbox:checked + .Radio__Radio {
    background: currentColor;
    transition: 0.15s linear;
}

.Radio__Radio:before {
     background: none;
}

.Radio__Radio:after {
    content: '';
    display: inline-block;
    position: absolute;

    width: 10px;
    height: 10px;
    border-radius: 50%;

    background: none;
    left: 4px;
    top: 4px;

    transition: 0.15s linear;
}

.Radio__checkbox:checked + .Radio__Radio:after {
    transition: 0.15s linear;
}


.Radio--icon .Radio__Radio:after,
.Radio--icon .Radio__Radio:before {
    content: '';
    display: inline-block;
    position: absolute;

    transform: rotateZ(-45deg);
    transition: 0.15s linear;
    border-radius: 0;;
}

.Radio--icon .Radio__Radio:after {
    width: 12px;
    height: 2px;
    top: 8px;
    left: 5px;
}

.Radio--icon .Radio__Radio:before {
    width: 2px;
    height: 5px;
    top: 8px;
    left: 4px;
}

.Radio__checkbox:checked + .Radio__Radio:after,
.Radio__checkbox:checked + .Radio__Radio:before {
    background: var(--text-color-invert) !important;
    transition: 0.15s linear;
}