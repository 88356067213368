.SurveyQuestion {
    border: 1px solid var(--separator);
    border-radius: 12px;
    padding: 15px;
    position: relative;
    margin-bottom: 20px;
}

.SurveyQuestion:hover {
    box-shadow: var(--shadow-1), var(--shadow-2);
}

.SurveyQuestion__option-row {
    display: grid;
    grid-template-columns: 1fr 30px;
    margin-bottom: 10px;
}

.SurveyQuestion__option-row--selector {
    display: grid;
    grid-template-columns: 30px 1fr 30px;
}

.SurveyQuestion__option-delete {
    color: var(--icon-color);
    cursor: pointer;
    margin: 5px;
    user-select: none;
}

.SurveyQuestion__option-delete:active {
    transform: scale(0.9);
}


.SurveyQuestion__delete {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    color: var(--icon-color);
    user-select: none;
}

.SurveyQuestion__delete:active {
    transform: scale(0.9);
}

.SurveyQuestion__header {
    margin-bottom: 20px;
}