.PageLogin {
    background: radial-gradient(50% 50% at 50% 50%, #1C2237 0%, #15172B 100%);
    background-size: cover;
    background-position: center;
    position: relative;
}

.PageLogin::after {
    content: '';
    display: inline-block;

    background: url('./../../../images/stars-background.svg');
    background-size: cover;
    background-position: center;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    animation: login_bg 3s linear infinite;
}

.Login__content {
    width: 300px;
    max-width: 100%;
    min-height: 100vh;

    margin: 0px auto;
    padding-top: 130px;

    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}

.Login__header,
.Login__header .Header {
    font-size: 90px;
    line-height: 82%;
    color: var(--white);
    user-select: none;
}

.Login__header {
    margin-bottom: 30px;
    margin-left: 6px;
}


.Login__input {
    color: var(--white);
    font-family: var(--font-family-headers);
}

.Login__placeholder {
    display: flex;
    white-space: nowrap;
}

.Login__placeholder-text:after {
    content: ' >';
}

.Login__input-element {
    background: none;
    border: none;
    color: none;
    width: 100%;
    color: var(--white);
    font-size: 16px;
    margin-left: 5px;
    font-family: var(--font-family-headers);
    outline: none;
}

.Login__notice {
    font-size: 20px;
    color: var(--arcade-yellow);
    font-family: var(--font-family-headers);
    text-align: center;
}

.Login__how-to {
    padding-top: 50px;
    animation: login_call_to_act 5s ease-in-out infinite;
}

.Login__footer {
    padding-top: 50px;
    font-size: 16px;
    padding-bottom: 30px;
}

.Login__submit-button {
    cursor: pointer;
}

.Login__error {
    color: var(--red);
    animation: shake 1s cubic-bezier(0,.44,.51,1.05);
}

@keyframes login_bg {
    from, to {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }
}

@keyframes login_call_to_act {
    from, to {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

@keyframes shake {
    15% {
        transform: scale(1.5);
    }

    10%, 30%, 50% {
        transform: translateX(-10px);
    }

    20%, 40%, 60% {
        transform: translateX(10px);
    }

    65% {
        transform: none;
    }
}