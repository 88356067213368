.CardGroup .PageHeader {
    display: grid;
    grid-template-columns: 1fr 50px;
    width: 100%;
    column-gap: 10px;
    margin: 20px 0;
}

.CardGroup .PageHeader__left {
    width: 100%;
    margin-right: 0;
}

.CardGroup .PageHeader__right {
    width: auto;
    margin-right: 0;
}


.CardGroup__add {
    display: inline-block;
    cursor: pointer;
    color: var(--accent);
    user-select: none;
    margin: 5px 0;
}

.CardGroup__add:active {
    transform: scale(0.9);
}