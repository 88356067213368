.PermissionSwitch--green {
    color: var(--green);
}

.PermissionSwitch--accent {
    color: var(--accent);
}

.PermissionSwitch--yellow {
    color: var(--yellow);
}

.PermissionSwitch--red {
    color: var(--red);
}