.Layout {
    display: grid;
    width: 100%;
    column-gap: 20px;
}

@media screen and (max-width: 820px) {
    .Layout {
        display: block;
    }
}