.Button {
    outline: none;
    background: none;

    font-family: var(--font-family-primary);
    font-size: 16px;

    padding: 6px 15px;
    margin: 3px 0;
    display: inline-block;
    border: none;
    border-radius: 5px;

    cursor: pointer;
    justify-content: center;

    position: relative;
    overflow: hidden;

    margin-right: 5px;
}

.Button:hover,
.Button:disabled {
    opacity: 0.8;
}

.Button:active {
    transform: scale(0.95);
}

.Button--primary {
    background: var(--text-color-primary);
    color: var(--text-color-invert);
}

.Button--secondary {
    border: 1px solid var(--text-color-primary);
    color: var(--text-color-primary);
}

.Button--positive {
    background: var(--button-green);
    color: var(--white);
}

.Button--negative {
    background: var(--button-red);
    color: var(--white);
}

.Button--tertiary {
    color: var(--accent);
    font-weight: bold;
    padding: 5px 0;
    margin-right: 0;
}

.Button a {
    color: inherit;
}

.Button__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: inherit;
}