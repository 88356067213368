.SelectorItem {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.SelectorItem__icon,
.SelectorItem__content {
    display: inline-block;
    vertical-align: middle;
}

.SelectorItem__icon {
    margin-right: 8px;
}

.SelectorItem__icon + .SelectorItem__content {
    width: calc(100% - 36px);
}

.Selector__item--selected .SelectorItem {
    color: var(--accent);
    font-weight: bold;
}