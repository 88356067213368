.Page {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.Page__sidebar {
    width: auto;
}

.Page__content {
    width: 100%;
    height: auto;
    overflow: auto;
    position: relative;
}

.Page--default .Page__content {
    padding: 30px;
    box-sizing: border-box;
}

.Page__loading {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1000px) {
    .Page__content {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 500px) {
    .Page--default .Page__content {
        padding: 15px;
    }
}