.BotCardItem {
    width: 100%;

    background: var(--card-background);
    border-radius: 12px;
    padding: 15px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.BotCardItem__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
}

.BotCardItem__clickable {
    color: inherit;
}

.BotCardItem__avatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 5px;

    /* !important потому что фон будет задаваться через стили */
    background: var(--image-placeholder);
    background-position: center !important;
    background-size: cover !important;

    box-shadow: inset 0 0 0 1px var(--separator);
}

.BotCardItem__title {
    font-family: var(--font-family-headers);
    flex: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BotCardItem__text {
    margin: 0;
}

.BotCardItem__link {
    color: var(--text-color-secondary);
    font-size: 12px;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}