.PageHeader {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.PageHeader__right {
    width: 100%;
    max-width: 380px;
    text-align: right;
}