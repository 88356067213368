.Sidebar {
    width: 300px;
    height: 100%;

    background: var(--foreground);
    padding: 30px;
    padding-bottom: 70px;

    position: relative;
    overflow: auto;
    box-sizing: border-box;
}

.Sidebar--opened {
    left: 0 !important;
    transition: left 0.3s ease-in-out;
}

.Sidebar__menu {
    margin-top: 30px;

    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.Sidebar__menu-separator {
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background: var(--sidebar-separator);
}

.Sidebar__account {
    position: absolute;
    bottom: 0;
    left: 0;

    padding: 30px;
    width: 100%;
    box-sizing: border-box;

    font-family: var(--font-family-headers);
    font-size: 16px;
}

.Sidebar__account-avatar {
    width: 40px;
    min-width: 40px;
    height: 40px;

    /* !important потому что фон будет устанавливаться через стили */
    background: var(--image-placeholder);
    background-size: cover !important;
    background-position: center !important;

    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.Sidebar__account-text-content {
    width: calc(100% - 50px);

    display: inline-block;
    vertical-align: middle;
}

.Sidebar__account-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Sidebar__account-actions {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
}

.Sidebar__account-action:not(:last-child):after {
    content: '*';
    margin-left: 5px;
}

.Sidebar__header {
    display: flex;
    flex-direction: row;
}

.Sidebar__title {
    color: var(--text-color-primary);
}

.Sidebar__header-link {
    width: 100%;
}

.Sidebar__close {
    color: var(--text-color-secondary);
    margin: 3px 0;
    cursor: pointer;
    display: none;
}

.Sidebar__close:active {
    transform: scale(0.9);
}


@media screen and (max-width: 1000px) {
    .Sidebar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;

        width: 100%;
        max-width: 400px;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        transition: left 0.3s ease-in-out;
        left: -100%;
        top: 0;
    }

    .Sidebar__menu {
        margin-bottom: 50px;
    }

    .Sidebar__close {
        display: inline-block
    }
}