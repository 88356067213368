.Pagination {
    margin: 20px 0;
    user-select: none;

    display: flex;
    column-gap: 5px;
}

.Pagination__chevron {
    color: var(--icon-color);
    padding: 3px 5px;
}

.Pagination__chevron,
.Pagination__page,
.Pagination__input {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 5px;
}

.Pagination__input .Input__element {
    width: 50px;
    text-align: center;
}

.Pagination__page {
    font-family: var(--font-family-primary);
    color: var(--icon-color);
    font-weight: bold;

    padding: 7.5px 15px;
}

.Pagination__chevron:hover,
.Pagination__page:hover {
    background: var(--hover-background);
}

.Pagination__page--current,
.Pagination__page--current:hover {
    background: var(--text-color-primary);
    color: var(--text-color-invert);
}