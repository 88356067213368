.ModalCard {
    padding: 20px;
    background: var(--background);
    border-radius: 12px;

    /* max-width: 500px; */
    margin: 50px 0;
    max-width: 100%;
    box-sizing: border-box;
}

@media screen and (max-width: 500px) {
    .ModalCard {
        margin: 0;
        border-radius: 12px 12px 0 0;
        width: 100%;
    }
}