.GlitchHeader {
    position: relative;
}

.GlitchHeader__child {
    text-shadow: 6px 0px 0px var(--glitch-blue), -6px 0px 0px var(--glitch-red);
    letter-spacing: 0.15em;
}

.GlitchHeader__child:last-child {
    position: absolute;
    top: 0;
    left: -5px;

    animation: glitch 3s linear infinite;
}

@keyframes glitch {
    0%, 15% {
        clip: rect( -5px, auto, 15px, 0 );
    }

    5% {
        clip: rect( 50px, auto, 95px, 0 );
    }

    10% {
        clip: rect( 0, 0, 0, 0 );
    }

    40% {
        clip: rect( -5px, auto, 15px, 0 );
    }

    45% {
        clip: rect( 80px, auto, 115px, 0 );
    }

    50% {
        clip: rect( 0, 0, 0, 0 );
    }


    80% {
        clip: rect( -5px, auto, 15px, 0 );
    }

    85% {
        clip: rect( 120px, auto, 165px, 0 );
    }

    90% {
        clip: rect( 0, 0, 0, 0 );
    }
}