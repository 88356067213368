.Breadcrumbs {
    margin: 0 -8px;
    margin-bottom: 10px;
}

.Breadcrumbs__item {
    color: var(--text-color-secondary);
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
}

.Breadcrumbs__item--link:hover {
    color: var(--text-color-primary);
    background: var(--hover-background);
}

.Breadcrumbs__separator {
    display: inline-block;
    vertical-align: middle;

    width: 2px;
    height: 12px;
    border-radius: 2px;
    transform: rotateZ(30deg);

    margin: 0 5px;
    background: var(--separator);
}