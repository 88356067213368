.HeaderSelector {
    display: flex;
    column-gap: 25px;
}

.HeaderSelector__tab {
    opacity: 0.5;
    cursor: pointer;
}

.HeaderSelector__tab--selected {
    opacity: 1;;
}