.AppSpinner {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    width: 100%;
    height: 100%;

    background: var(--background);

    display: flex;
    justify-content: center;
    align-items: center;
}

.AppSpinner__spinner {
    display: inline-block;
}