.ChipSelect {
    width: 100%;
    position: relative;
}

.ChipSelect__element {
    width: 100%;
    padding: 10px;

    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;
    min-height: 45px;
}

.ChipSelect__input {
    display: inline-block;
    vertical-align: middle;

    outline: none;
    border: none;
    background: none;

    font-family: var(--font-family-primary);
    font-size: 14px;
}

.ChipSelect__list {
    position: absolute;
    top: calc( 100% + 5px );
    left: 0;
    z-index: 500;

    width: 100%;
    padding: 0;
    box-sizing: border-box;

    border-radius: 5px;
    border: 0px solid var(--separator);

    background: var(--background);
    box-shadow: var(--shadow-1), var(--shadow-2);

    display: flex;
    flex-direction: column;
    row-gap: 5px;

    display: none;
    overflow: auto;

    max-height: 250px;
}

.ChipSelect__input:focus ~ .ChipSelect__list,
.ChipSelect__list:active {
    display: block;
}

.ChipSelect__list-item {
    padding: 8px 10px;
    cursor: pointer;
    user-select: none;
}

.ChipSelect__list-item:hover {
    background: var(--hover-background);
}