.Error {
    background: rgba(255, 67, 67, 0.16);
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    margin:  10px 0;
}

.Error__content {
    /* margin-top: 10px; */
}