.SectionSelectorItem {
    background: var(--card-background);

    padding: 15px;
    border-radius: 12px;
    margin-bottom: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;

    text-decoration: none;
    color: var(--color-primary);
}

.SectionSelectorItem:hover {
    color: inherit;
}

.SectionSelectorItem__icon {
    width: 40px;
    height: 40px;
}

.SectionSelectorItem__icon svg {
    height: 40px;
}

.SectionSelectorItem__text-content {
    width: 100%;
}

.SectionSelectorItem__title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /* Почему то шрифты Gilroy и Pixel Cyr смещаются относительно друг друго */
    padding-left: 2px;
}

.SectionSelectorItem__chevron {
    width: 28px;
    height: 28px;
    color: var(--icon-color);
}

.SectionSelectorItem:hover .SectionSelectorItem__chevron {
    transform: translateX(5px);
}