.ImageGridHeader {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%),
                url('./../../../../../images/welcome_header.png');
    background-size: cover;
    background-position: center;

    width: 100%;
    height: 420px;

    position: relative;
    text-align: center;
}

.ImageGridHeader__image {
    width: 210px;
    height: 210px;
    border-radius: 40px;

    /* Important потому что значение фона
     * будет устанавливаться через атрибут style */
    background: var(--image-placeholder);
    background-size: cover !important;
    background-position: center !important;

    display: inline-block;
    margin: 60px 0 30px 0;
}

.ImageGridHeader__text {
    text-shadow:  -4px -4px 0 var(--white),
                    0   -4px 0 var(--white),
                    4px -4px 0 var(--white),
                    4px  0   0 var(--white),
                    4px  4px 0 var(--white),
                    0    4px 0 var(--white),
                    -4px  4px 0 var(--white),
                    -4px  0   0 var(--white);
}