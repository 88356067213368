.Table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.Table__tr:hover {
    background: var(--hover-background);
}

.Table__tr:not(:last-of-type) {
    border-bottom: 1px solid var(--separator);
}

.Table__tr--head {
    font-weight: bold;
    border-bottom: 1px solid var(--text-color-primary) !important;
}

.Table__td {
    padding: 10px;
}

.Table__td:empty:after {
    content: '—';
}

.Table__action {
    width: 24px;
    height: 24px;
    margin-right: 10px;

    display: inline-block;
    vertical-align: middle;

    cursor: pointer;
    user-select: none;
}

.Table__action:active {
    transform: scale(0.95);
}

.Table__action--red {
    color: var(--red);
}

.Table__action--blue {
    color: var(--accent);
}

.Table__action--yellow {
    color: var(--yellow);
}

.Table__action a {
    color: inherit;
}