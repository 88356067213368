:root {
    --font-family-primary: 'Gilroy';
    --font-family-headers: 'Pixel Cyr';

    --text-color-primary: #000000;
    --text-color-secondary: #888888;


    --input-bg: #F0F0F0;
    --input-hover: #DDDDDD;
    --input-icon: #AAAAAA;

    --text-color-invert: #FFFFFF;
    --background: #FFFFFF;

    --glitch-red: #FF0000;
    --glitch-blue: #82CAFF;

    --white: #FFFFFF;
    --arcade-yellow: #FFE600;
    --accent: #3196F2;
    --red: #DA4444;
    --yellow: #FBBC1C;
    --green: #2FAE0F;

    --button-red: #DA4444;
    --button-green: #2FAE0F;

    --image-placeholder: rgba(0, 0, 0, 0.2);
    --card-background: #FAFAFA;
    --icon-color: #A6A6A6;
    --separator: #F0F0F0;

    --color-primary: #000000;
    --foreground: #F9F6F6;
    --hover-background: rgba( 0, 0, 0, 0.1 );

    --sidebar-separator: rgba( 0, 0, 0, 0.06 );

    --scrollbar-track: rgba( 0, 0, 0, 0.05 );
    --scrollbar-thumb: rgba( 0, 0, 0, 0.1 );

    --link-color: #73ADF1;
    --link-color-hover: #639bdb;

    --images-inset: rgba(0, 0, 0, 0.1);

    --modal-cover: rgba( 0, 0, 0, 0.3 );

    --shadow-1: 0px 1px 2px rgba(0, 0, 0, 0.1);
    --shadow-2: 0px 1px 20px rgba(0, 0, 0, 0.15);
    --shadow-input: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

    --page-preview-keyboard-button: rgba(37, 37, 37, 0.4);
    --page-preview-keyboard-button-hover: rgba(37, 37, 37, 0.5);
}