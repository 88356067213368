.PhoneFrame {
    width: 100%;
    max-width: 230px;
    position: relative;
}


.PhoneFrame__frame {
    width: 100%;
    padding-top: 204%;

    background: url('../../images/meizu_phone_frame.png');
    background-size: cover;
    background-position: center;

    position: relative;
    z-index: 10;
}

.PhoneFrame__screenshot {
    background: #000;
    background-size: cover !important;
    background-position: center !important;

    height: calc(100% - 7.9%);
    width: calc(100% - 6%);

    position: absolute;
    top: 3.9%;
    left: 3%;

    border-radius: 10px;
    z-index: 9;
}