.BotPageNavigation {
    width: 100%;
    /* width: 380px; */
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    float: right;
}

.BotPageNavigation__button {
    width: 100%;
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 10px;
    color: var(--text-color-primary);
    text-align: left;
    padding: 5px 0px;
    border-radius: 5px;
}

.BotPageNavigation__button:hover {
    color: var(--accent);
}