.HelpTooltip {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.HelpTooltip__icon {
    color: var(--icon-color);
    opacity: 0.6;
    cursor: help;
}

.HelpTooltip__icon:hover {
    opacity: 1;
}

.HelpTooltip__tooltip {
    position: absolute;
    top: calc( 100% + 5px );
    left: 0;
    z-index: 500;
    
    width: 320px;
    padding: 10px;
    box-sizing: border-box;

    border-radius: 5px;
    border: 0px solid var(--separator);

    background: var(--background);
    box-shadow: var(--shadow-1), var(--shadow-2);

    display: none;
    overflow: auto;

    max-height: 250px;
}

.HelpTooltip__icon:hover ~ .HelpTooltip__tooltip,
.HelpTooltip__tooltip:hover {
    display: block;
}