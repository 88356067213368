.Switch {
    display: inline-block;
    margin: 3px 0;
}

.Switch__label {
    display: flex;
    column-gap: 5px;
    align-items: center;
}

.Switch__checkbox {
    display: none;
}

.Switch__switch {
    width: 32px;
    height: 22px;
    border-radius: 12px;
    background: var(--input-bg);
    position: relative;
    transition: 0.15s linear;
    cursor: pointer;
}

.Switch__switch:hover {
    background: var(--input-hover);
}

.Switch__checkbox:checked + .Switch__switch {
    background: currentColor;
    transition: 0.15s linear;
}

.Switch__switch:after {
    content: '';
    display: inline-block;
    position: absolute;

    width: 16px;
    height: 16px;
    border-radius: 50%;

    background: var(--background);
    left: 3px;
    top: 3px;

    transition: 0.15s linear;
}

.Switch__checkbox:checked + .Switch__switch:after {
    left: 13px;
    transition: 0.15s linear;
}