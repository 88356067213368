@font-face {
    font-family: 'Pixel Cyr';
    src: url('fonts/PixelCyr-Normal.eot');
    src: url('fonts/PixelCyr-Normal.eot?#iefix') format('embedded-opentype'),
        url('fonts/PixelCyr-Normal.woff2') format('woff2'),
        url('fonts/PixelCyr-Normal.woff') format('woff'),
        url('fonts/PixelCyr-Normal.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('fonts/gilroy-regular.eot');
    src: url('fonts/gilroy-regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/gilroy-regular.woff2') format('woff2'),
        url('fonts/gilroy-regular.woff') format('woff'),
        url('fonts/gilroy-regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: local('fonts/Gilroy Medium'), local('Gilroy-Medium'),
        url('fonts/Gilroy-Medium.woff2') format('woff2'),
        url('fonts/Gilroy-Medium.woff') format('woff'),
        url('fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

html,
body {
    font-family: var(--font-family-primary);
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}
  
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    background: var(--scrollbar-track);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
    border-radius: 20px;
}

* {
    -webkit-tap-highlight-color: transparent;
}

a {
    text-decoration: none;
    color: var(--link-color);
}

a:hover {
    color: var(--link-color-hover);
}