.SidebarMenuItem {
    width: 100%;

    display: flex;
    column-gap: 10px;
    align-items: center;

    text-decoration: none;
    color: inherit;

    padding: 5px 8px;
    margin: 0 -8px;

    border-radius: 5px;
}

.SidebarMenuItem:hover {
    background: var(--hover-background);
    color: inherit;
}

.SidebarMenuItem__icon {
    width: 28px;
    height: 28px;
}

.SidebarMenuItem__icon svg {
    height: 28px;
}

.SidebarMenuItem__text {
    font-family: var(--font-family-headers);
    font-size: 16px;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}