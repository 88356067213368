.SectionSelector {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 0px;
    margin: 20px 0;
}

.SectionSelector__delimiter {
    grid-column: span 2;
    height: 1px;
    background: var(--separator);
    margin: 5px 0;
}

@media screen and (max-width: 1050px) {
    .SectionSelector {
        grid-template-columns: 1fr;
    }
}