.FormRow {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    column-gap: 10px;
}

.FormRow__left {
    font-size: 14px;
    padding: 8px 0;
    width: 200px;
}

.FormRow__content {
    width: 100%;
}

.FormRow__content-grid {
    display: grid;
}

@media screen and ( max-width: 1150px) {
    .Layout .FormRow {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 500px) {
    .FormRow {
        flex-wrap: wrap;
    }
}