.PagePreview {
    width: 100%;
}

.PagePreview__chat {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin: 5px 0;
    background: url('../../../../images/chat.png');
    box-sizing: border-box;
    box-shadow: inset 0 0 0 1px var(--images-inset);

    display: flex;
    flex-direction: column;
    row-gap: 6px;
}

.PagePreview__message {
    padding: 10px 15px;
    box-sizing: border-box;
    background: var(--background);
    border-radius: 5px;
    box-shadow: var(--shadow-1);
    word-break: break-all;
}

.PagePreview__keyboard {
    display: flex;
    row-gap: 6px;
    flex-direction: column;
}

.PagePreview__keyboard-row {
    width: 100%;
    display: flex;
    column-gap: 6px;
}

.PagePreview__keyboard-button {
    padding: 8px 15px;
    cursor: pointer;
    display: inline-block;
    background: var(--page-preview-keyboard-button);
    text-align: center;
    width: 100%;
    border-radius: 5px;
    backdrop-filter: blur(10px);
    color: var(--text-color-invert);
    user-select: none;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.PagePreview__keyboard-button--add {
    border: 1px solid var(--page-preview-keyboard-button);
    background: none;
    color: var(--text-color-primary);
}

.PagePreview__keyboard-button:hover {
    color: var(--text-color-invert);
    background: var(--page-preview-keyboard-button-hover);
}

.PagePreview__keyboard-button:active {
    transform: scale(0.95);
}

.PagePreview__notice {
    font-size: 14px;
    color: var(--text-color-secondary);
}

.PagePreview__head {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
}