.ItemCard {
    background: var(--card-background);
    border-radius: 12px;
    box-shadow: var(--shadow-1);
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    color: var(--text-color-primary);
}

.ItemCard__title {
    font-weight: bold;
    font-family: var(--font-family-primary);
}

.ItemCard__info {
    margin-top: 5px;
    font-size: 14px;
    color: var(--text-color-secondary);
}

.ItemCard:hover {
    box-shadow: var(--shadow-2);
}