.Chip {
    background: var(--text-color-primary);
    color: var(--text-color-invert);

    padding: 3px 8px;
    font-size: 14px;
    display: inline-block;
    border-radius: 3px;
}

.Chip__in,
.Chip__delete {
    display: inline-block;
    vertical-align: middle;
}

.Chip__in {
    padding-right: 5px;
}

.Chip__delete {
    cursor: pointer;
}