.SelectOption {
    padding: 8px 10px;
    font-size: 14px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Select__element .SelectOption,
.Select__element .SelectOption:hover {
    background: none;
    padding: 0;
}

.SelectOption:hover {
    background: var(--hover-background);
}