.OfflineIndicator {
    position: fixed;
    bottom: 0;
    left: 0;

    width: 100%;
    padding: 10px 15px;
    box-sizing: border-box;

    background: var(--red);
    color: var(--white);

    font-family: var(--font-family-headers);
    text-align: center;
}