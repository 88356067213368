.TabbarItem {
    height: 100%;
    min-width: 50px;
    max-width: 80px;
    width: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    color: var(--text-color-primary);
}

.TabbarItem:hover {
    background: var(--hover-background);
}

.TabbarItem__icon {
    width: 28px;
    height: 28px;
}

.TabbarItem__icon-with-text {
    width: 20px;
    height: 20px;
}

.TabbarItem__text {
    width: 100%;
    white-space: nowrap;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;

    text-align: center;
}