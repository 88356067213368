.DeleteModal {
    text-align: center;
    max-width: 400px;
}

.DeleteModal__text {
    margin: 20px 0;
}

.DeleteModal__buttons {
    display: flex;
    column-gap: 10px;
    justify-content: center;
}